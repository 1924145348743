<template>
  <EditOnClick
    v-model="value"
    type="text"
    v-on:update="(e) => valueUpdated()"
  />
  <button
    class="btn btn-transparent btn-sm"
    :title="$t(`filesWithLabel.moreInformation2`)"
    v-on:click="show"
  >
    <BaseIcon name="document" />
    <span class="counter-badge regular-10" v-if="modelValue?.count">{{modelValue.count}}</span>
  </button>
  <FilesModal :id="id" :row="row" ref="modal" />
</template>
<script>
import { defineAsyncComponent } from "vue";
import FilesModal from "@/components/modals/FilesModal";
import EditOnClick from "@/components/inputs/EditOnClick";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "Files",
  components: { EditOnClick, FilesModal, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      edit: false,
      value:
        this.modelValue?.count !== undefined
          ? this.modelValue.value
          : this.modelValue,
    };
  },
  emits: ["update:modelValue", "update", "update:val"],
  methods: {
    show() {
      this.$refs.modal.showModal();
    },
    valueUpdated() {
      this.$emit("update:modelValue", this.value);
      this.$emit("update", this.value);
    },
  },
};
</script>
