<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col"></div>
            <div class="col-auto">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                :aria-label="$t(`confirmModal.close`)"
              ></button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <b>{{ $t("customRowModal.files") }}</b>
              </div>
              <div
                class="badge badge-outline mb-2 w-100 d-block text-start"
                v-for="file in files"
                :key="file.id"
              >
                <div class="row">
                  <div class="col text-wrap">
                    <div class="mb-1">{{ file.name }}</div>
                    <span>{{ Math.round(file.size / 1000) / 1000 }} MB</span>
                    <div>{{ file.comment }}</div>
                    <div>{{ formatDate(file.created_at) }}</div>
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn btn-transparent"
                      v-on:click="download(file)"
                    >
                      <base-icon name="download" />
                    </button>
                    <button
                      class="btn btn-transparent"
                      v-on:click="deleteFile(file)"
                    >
                      <base-icon name="trash" />
                    </button>
                  </div>
                </div>
              </div>
              <file-pond
                name="file"
                ref="pond"
                :acceptedFileTypes="[
                  'image/*',
                  'application/pdf',
                  '.docx',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'text/plain',
                ]"
                label-idle="Drop files here..."
                v-bind:allow-multiple="true"
                v-bind:files="myFiles"
                v-on:init="handleFilePondInit"
                :server="server"
                :credits="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmModal
    ref="deleteModal"
    :title="$t(`filesModal.deleteFile`)"
    text="<ul><li>You will delete<b> the file from the server</b></li>
<li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>"
    cancel-button="No, Keep file"
    ok-button="Yes, delete file"
    v-on:success="deleteFileEvent"
  />
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import BaseIcon from "@/components/icons/BaseIcon";
import http from "@/modules/http";
import store from "@/store";
import date from "@/modules/date";
import ConfirmModal from "./ConfirmModal";
import tenant from "../../modules/tenant";
const bootstrap = require("bootstrap");

// Create component
const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  name: "FilesModal",
  components: { ConfirmModal, BaseIcon, FilePond },
  props: {
    id: Number,
    type: String,
    row: Object,
  },
  data() {
    return {
      value: this.modelValue,
      myModal: undefined,
      files: [],
      data: {},
      edit: false,
      myFiles: [],
      deleteRef: null,
      server: {
        process: this.upload,
      },
    };
  },
  computed: {
    rowId() {
      return this.row.row_key
        .replace("custom_", "")
        .replace("characteristics_", "");
    },
    page() {
      if (this.row.row_key.indexOf("characteristics_") > -1) {
        return "product";
      } else {
        return "crm";
      }
    },
  },
  methods: {
    formatDate(val) {
      return date.format(val);
    },
    download(file) {
      window.location = `${tenant.getApiBaseUrl()}/${
        this.page
      }-row-file-download/${file.uuid}?_token=${store.state.token}`;
    },
    downloadZip(file) {
      window.location = `${tenant.getApiBaseUrl()}/${
        this.page
      }-row-zip-file-download/${file.uuid}?_token=${store.state.token}`;
    },
    deleteFile(file) {
      this.deleteRef = file;
      this.$refs.deleteModal.showModal();
    },
    deleteFileEvent() {
      http
        .fetch(
          `/${this.page}-row-file-upload/${this.deleteRef.id}`,
          {
            uuid: this.deleteRef.uuid,
          },
          true,
          "DELETE"
        )
        .then(() => {
          this.files.splice(
            this.files.findIndex((e) => e.id === this.deleteRef.id),
            1
          );
          this.deleteRef = null;
        });
    },
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      this.load();
    },
    load() {
      http
        .fetch(`/${this.page}-row-file-list/${this.rowId}/${this.id}`)
        .then((data) => {
          this.files = data.data;
        });
    },
    upload(fieldName, file, metadata, load, error, progress, abort) {
      const request = new XMLHttpRequest();
      const formData = new FormData();
      formData.append(fieldName, file, file.name);
      formData.append(
        this.page === "crm" ? "crm_item_id" : "product_variant_id",
        this.id
      );
      formData.append(
        this.page === "crm"
          ? "crm_custom_field_type_id"
          : "characteristics_type_id",
        this.rowId
      );

      request.open(
        "POST",
        `${tenant.getApiBaseUrl()}/${this.page}-row-file-upload`
      );

      request.setRequestHeader("Authorization", "Bearer " + store.state.token);
      request.setRequestHeader("Accept", "application/json");

      request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      request.onload = function () {
        if (request.status >= 200 && request.status < 300) {
          let data2 = JSON.parse(request.responseText);

          console.log(data2);

          load(request.responseText);
        } else {
          error("oh no");
        }
      };

      request.send(formData);

      return {
        abort: () => {
          request.abort();
          abort();
        },
      };
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");
    },
  },
};
</script>
